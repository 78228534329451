<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">객실 타입</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    v-model="searchOptions.roomType"
                    type="lookupDetail-condition"
                    :dataSource="searchOptions.roomTypeOptions"
                    :fields="commonCodeFields"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">회원 등급</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    v-model="searchOptions.memberGrade"
                    type="lookupDetail-condition"
                    :dataSource="searchOptions.memberGradeOptions"
                    :fields="commonCodeFields"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    :disabled="baseRateSetting"
                    v-model="searchDateInputDateRange"
                ></input-date-range>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="fetch"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01" :class=" !this.baseRateSetting && 'height-adjustment' ">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">객실 요금 등록</div>
            </div>
            <div class="header-right">
              <ul class="header-button" style="margin-right: 140px">
                <li class="save keyColor" v-if="baseRateSetting">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      :is-key-color="true"
                      v-on:click.native="save"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="refresh">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="cancelRoomBaseRateGrid">
                    초기화
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel달력
                  </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">
                  멀티 편집모드
                </div>
                <div class="switch">
                  <ejs-switch
                      v-model="isModifyMode"
                      :checked="isModifyMode"
                      @change="onModifySwitchChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section-caption">
            <div class="caption-navigation">
              <ejs-tab
                  ref="tabByGrid"
                  :showCloseButton="false"
                  heightAdjustMode="Auto"
                  :selected="onRoomFeeTabSelected"
                  overflowMode="Popup"
              >
                <e-tabitems>
                  <e-tabitem
                      v-for="n in tabHeader" :key="n"
                      :header="{ text: `${n}` }"
                  />
                </e-tabitems>
              </ejs-tab>
            </div>
          </div>
          <div
              class="section-body"
          >
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="roomBaseRateGrid"
                  v-bind="gridOption"
                  :dataSource="roomBaseRateList"
                  :allowExcelExport="true"
                  @cellSaved="roomBaseRateGridSaveCell"
                  @cellSelected="roomBaseRateGridCellSelected"
                  @queryCellInfo="roomBaseRateGridCustomiseCell"
                  @headerCellInfo="roomBaseRateGridHeaderCellInfo"
              />
            </div>
          </div>
        </section>

        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-02" v-if="!baseRateSetting" style="overflow: auto">
          <div class="section-body">
            <Calendar
                ref="calendar"
                :saveCurrenDate="this.saveCurrenDate"
                :baseList="this.roomBaseRateList"
                :fromDate="this.searchOptions.bsnDateFrom"
                :toDate="this.searchOptions.bsnDateTo"
                :dataSource="this.roomRateCalendar"
                @saveCalendar="save"
            />
          </div>
        </section>
      </article>
    </div>
    <edit-multiple-columns-popup
        v-if="isEditMultipleColumnsPopupOpen"
        ref="editMultipleColumnsPopup"
        @popupConfirm="onEditMultipleColumnsPopupConfirm"
        @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<script>

import EditMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import InputDateRange from "@/components/common/datetime/InputDateRange";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Edit, ExcelExport, ForeignKey, Resize, Selection} from "@syncfusion/ej2-vue-grids";
import {commonCodesGetCommonCode,commonCodeGetComCodeDefaultValue} from "@/utils/commonCodes";
import {getFormattedDate} from "@/utils/date";
import moment from "moment";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import Calendar from "@/views/room-reservation-management/component/calendar";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
name: "RoomRateRegistration",
  components:{
    EjsGridWrapper,
    InputDateRange,
    EditMultipleColumnsPopup,
    Calendar,
    ErpButton,
    // Grid
  },
  mixins: [confirmDialogMixin],
  created() {
    this.searchOptions.memberGradeOptions = commonCodesGetCommonCode(
        "MEMBER_GRADE",
        true
    );
    if (
        this.searchOptions.memberGradeOptions.findIndex(
            (obj) => obj.comCode === ""
        ) === -1
    ) {
      this.searchOptions.memberGradeOptions.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.roomTypeOptions = commonCodesGetCommonCode("ROOM_TYPE", true);

    if (this.searchOptions.roomTypeOptions.findIndex((obj) => obj.comCode === "") === -1) {
      this.searchOptions.roomTypeOptions.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.memberGrade = "";
    this.searchOptions.roomType = "";

    this.searchOptions.bsnDateFrom = getFormattedDate(moment().format());
    this.searchOptions.bsnDateTo = getFormattedDate(moment().set('month',Number(moment().format("M"))+2).format());

  },
  computed:{
    isPopupOpened() {
      return (
          this.isEditMultipleColumnsPopupOpen
      );
    },
    searchDateInputDateRange: {
      get: function () {
        return {
          from: this.searchOptions.bsnDateFrom,
          to: this.searchOptions.bsnDateTo
        };
      },
      set: function (dateRange) {
        this.searchOptions.bsnDateFrom = dateRange.from;
        this.searchOptions.bsnDateTo = dateRange.to;
      }
    },
    gridOption() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize, ExcelExport],
        selectionSettings: {
          type: "Multiple",
          mode: "Both",
          enableToggle: false,
        },
        columns:[
          {
            field: "roomType",
            headerText: "객실타입",
            type: "string",
            width: 90,
            allowEditing: false,
            groupCode: "ROOM_TYPE",
            isCommonCodeField: true,
          },
          {
            field: "memberGrade",
            headerText: "회원등급",
            type: "string",
            width: 90,
            allowEditing: false,
            groupCode: "MEMBER_GRADE",
            isCommonCodeField: true,
          },
          {
            field:"stdPaymtBySun",
            headerText:'일',
            type: "string",
            width:90,
            textAlign: 'right',
            isNumericType: true,
            allowFiltering: false,
          },
          {
            field: "stdPaymtByMon",
            headerText: "월",
            type: "string",
            width: 90,
            textAlign: 'right',
            isNumericType: true,
            allowFiltering: false,
          },
          {
            field: "stdPaymtByTue",
            headerText: "화",
            type: "string",
            width:90,
            textAlign: 'right',
            isNumericType: true,
            allowFiltering: false,
          },
          {
            field:"stdPaymtByWed",
            headerText:'수',
            type: "string",
            width:90,
            textAlign: 'right',
            isNumericType: true,
            allowFiltering: false,
          },
          {
            field:"stdPaymtByThu",
            headerText:'목',
            type: "string",
            width:90,
            textAlign: 'right',
            isNumericType: true,
            allowFiltering: false,
          },
          {
            field:"stdPaymtByFri",
            headerText:'금',
            type: "string",
            width:90,
            textAlign: 'right',
            isNumericType: true,
            allowFiltering: false,
          },
          {
            field:"stdPaymtBySat",
            headerText:'토',
            type: "string",
            width:90,
            textAlign: 'right',
            isNumericType: true,
            allowFiltering: false,
          },
        ]
      };
    }
  },
  data() {
    return{
      tabSelectIndex:0,
      tabHeader:['기준 요금 설정','기간별 요금 설정'],
      baseRateSetting: true,
      isModifyMode: false,
      saveCurrenDate:null,
      commonCodeFields: { text: "comName", value: "comCode" },
      searchOptions: {
        bsnDateFrom: null,
        bsnDateTo: null,
        memberGrade: null,
        roomType: null,
        memberGradeOptions: [],
        roomTypeOptions: [],
      },
      roomBaseRateList:[],
      roomRateCalendar:[],
      isEditMultipleColumnsPopupOpen: false,
    };
  },
  methods:{
   async onRoomFeeTabSelected(args) {
     if (this.tabSelectIndex !== args.selectedIndex) {
       let correctionData = null;
       (this.baseRateSetting)
           ? correctionData = this.$refs.roomBaseRateGrid.getBatchChanges().changedRecords
           : correctionData = this.$refs.calendar.changeData();
       if (correctionData.length) {
         if (!await this.confirm("수정사항이 있습니다. 탭을 이동하시겠습니까?")) {
           this.$refs.tabByGrid.select(this.tabSelectIndex);
           return;
         } else {
           this.tabSelectIndex = args.selectedIndex;
           this.baseRateSetting = !this.baseRateSetting;

          await this.tabSetting();
         }
       } else {
         this.tabSelectIndex = args.selectedIndex;
         this.baseRateSetting = !this.baseRateSetting;
         await this.tabSetting();
       }
     }

    },
    async tabSetting() {
      if(!this.baseRateSetting){
        if (this.searchOptions.roomTypeOptions.findIndex((obj) => obj.comCode === "") !== -1) {
          this.searchOptions.roomTypeOptions = await commonCodesGetCommonCode("ROOM_TYPE", true);
          if(this.searchOptions.roomType === ""){
            this.searchOptions.roomType = await commonCodeGetComCodeDefaultValue("ROOM_TYPE");
          }
        }
      } else {
        if (this.searchOptions.roomTypeOptions.findIndex((obj) => obj.comCode === "") === -1) {
          this.searchOptions.roomTypeOptions = [{
            comCode: "",
            comName: "전체",
          },...this.searchOptions.roomTypeOptions];
          this.searchOptions.roomType = "";
        }
      }
      await this.fetch();
    },
    cancelRoomBaseRateGrid() {
      this.$refs.roomBaseRateGrid.batchCancel();
      if(!this.baseRateSetting) {
        this.$refs.calendar.refresh();
      }
    },
    async save(currentDate) {
      if(this.baseRateSetting){
        let changeData = this.$refs.roomBaseRateGrid.patchBatchChanges();
        const batchCurrentViewRecords = this.$refs.roomBaseRateGrid.getBatchCurrentViewRecords();
        if (changeData.changedRecords && changeData.changedRecords.length > 0) {
          const saveData = changeData.changedRecords.map(changedRecord => {
            const data = batchCurrentViewRecords.find(record => record._rid === changedRecord._rid);
            return {
              ...changedRecord,
              memberGrade: data.memberGrade,
              roomType: data.roomType,
            };
          });
          await GolfErpAPI.patchRoomRateBaseSetting(saveData);
          await this.infoToast("수정되었습니다");
          await this.fetch();
        } else {
          await this.errorToast("수정된 데이터가 없습니다");
        }
      }else {
        const changeData = this.$refs.calendar.changeData();
        if(changeData.length){
          console.log(changeData);
          await GolfErpAPI.patchRoomRatePeriodSetting(changeData);
          await this.infoToast("수정되었습니다");
          await this.fetch("",currentDate);
        }else {
          await this.errorToast("수정된 데이터가 없습니다");
        }
      }

    },
    async fetch(event,currentDate=undefined) {
      let bsnDateBefore = null;
      if(this.baseRateSetting) {
        const { roomPaymtStds: data } =  await GolfErpAPI.getRoomRateBaseSetting(this.searchOptions);
        this.roomBaseRateList = data.map((data)=>{
          const isTopLine = bsnDateBefore && bsnDateBefore !== data.roomType;
          const opcityData = (!bsnDateBefore || bsnDateBefore !== data.roomType);
          bsnDateBefore = JSON.parse(JSON.stringify(data.roomType));
          return {
            ...data,
            isTopLine: isTopLine,
            opcityData: opcityData
          };
        });
      } else {
        const {
          roomPaymtStds: data,
          roomPaymts: dataCalendar,
        } = await GolfErpAPI.getRoomRatePeriodSetting(this.searchOptions);
        this.saveCurrenDate = currentDate;
        this.roomBaseRateList = data.map((data)=>{
          const opcityData = (!bsnDateBefore || bsnDateBefore !== data.roomType);
          bsnDateBefore = JSON.parse(JSON.stringify(data.roomType));
          return {
            ...data,
            opcityData: opcityData
          };
        });
        this.roomRateCalendar = dataCalendar.map(item => ({
          ...item,
          roomPaymt: item.roomPaymt || 0,
        }));
      }
    },
    roomBaseRateGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText
          },
        },
        node,
      } = args;
      if(headerText === '토'){
        node.style.color = '#4b89dc';
      } else if(headerText === '일') {
        node.style.color = '#c80000';
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.gridOption.columns
            ?.filter(item => item.allowEditing)
            ?.map(item => item.field);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
        }
      }
    },
    roomBaseRateGridCustomiseCell(args) {
      const {
        column: {
          field,
          allowEditing
        },
        data,
        cell,
      } = args;
      if (allowEditing) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
        if(data[field]===0){
          cell.innerText="-";
        }
      }
      if(field==="roomType" && !data.opcityData){
        cell.innerText="";
      }
      if (data["isTopLine"]) {
        cell.style.borderTop = '1px solid #000';
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.gridOption.columns
            ?.filter(item => item.allowEditing)
            ?.map(item => item.field);
        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      }
    },
    roomBaseRateGridSaveCell(args) {
      if(!this.baseRateSetting){
        const {
          columnName,
          rowData:row,
          value
        } =args;
        console.log(args);
        this.roomBaseRateList.forEach((data)=>{
          if(data.memberGrade==row.memberGrade&&data.roomType==row.roomType){
            data[columnName]=Number(value);
          }
        });

      }
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.gridOption.selectionSettings = isChecked
          ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
          : {mode: 'Both'};
      this.$refs.roomBaseRateGrid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async roomBaseRateGridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.gridOption.columns
          ?.forEach(column => {
            if (column?.columns) {
              column.columns
                  ?.forEach(c => columns.push(c));
            } else {
              columns.push(column);
            }
          });
      const allowedEditColumns = columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
      const column = columns[cellIndex - 2];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      // TODO:임시로 만들어둠 (GH)
      //
      // const checkToplineIdx = selectedRowIndexes.map((data) => data+1);
      // console.log("checkToplineIdx===>",checkToplineIdx);
      // const checkLength = commonCodesGetCommonCode("MEMBER_GRADE", true).length;
      // if(checkToplineIdx.length>checkLength || !(checkToplineIdx[checkLength-1]%checkLength===0)){
      //   this.errorToast('같은 타입을 선택하여주시기 바랍니다');
      //   return;
      // }

      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
          column,
          selectedRowIndexes
      );
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.roomBaseRateGrid.updateCell(
            index,
            field,
            value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    onClickExcel() {
      this.$refs.roomBaseRateGrid.excelExport();
    },
  }
};
</script>

<style scoped>
body .body-article {
  display: flex;
  flex-flow: column;
}
.height-adjustment {
  height: 300px;
}
</style>