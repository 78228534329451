<template>
  <div>
    <ejs-dialog
        ref="roomPayCorrectionPopup"
        :header="`객실요금설정  ( ${list.bsnDate}  ${selectDate} )`"
        :width="280"
        :isModal="true"
        :allowDragging="true"
        :showCloseIcon="true"
        :animationSettings="{ effect: 'None' }"
        :close="onPopupClosed"
    >
      <div class="window editMultipleColumns">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li
                    class="field"
                >
                  <div class="title">
                    {{commonCodesGetComName("ROOM_TYPE", list.roomType) }}
                    {{commonCodesGetComName("MEMBER_GRADE", list.memberGrade)}}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-number
                          ref="focusArea"
                          v-model="list.roomPaymt"
                          @keydown.native.enter="submit"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button button-div="SAVE" @click.native="submit">
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closePopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import InputNumber from "@/components/common/InputNumber";
import {
  commonCodesGetComName,
  commonCodesGetCommonCode,
} from '@/utils/commonCodes';
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: 'roomPayCorrectionPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputNumber,
    ErpButton,
  },
  data() {
    return {
      orgList:{},
      list:{},
      pay:'',
      date:["일", "월", "화", "수", "목", "금", "토"],
      selectDate:null,
    };
  },
  methods: {
    commonCodesGetComName,
    commonCodesGetCommonCode,
    onPopupClosed() {
      this.$emit('popupClosed');
      this.$refs.roomPayCorrectionPopup.hide();
    },
    async showPopup(data,nIdx){
      //orgList , list 두개다 깊은 복사가 필요
      // orgList = 데이터가 바뀌었나 검사값 list = 깊은복사를 하지않으면 상위 컴포넌트 데이터를 바로 건들임
      this.orgList = JSON.parse(JSON.stringify(data));
      this.list = JSON.parse(JSON.stringify(data));
      this.selectDate = this.date[nIdx];
      this.$nextTick(() => {
        this.$refs.focusArea.focusInEvnt();
      });
    },
    closePopup() {
      this.$refs.roomPayCorrectionPopup?.hide();
    },
    async submit() {
      this.orgList.roomPaymt == this.list.roomPaymt
          ? this.onPopupClosed()
          : this.$emit('popupConfirm',this.list);
    },
  },
};
</script>