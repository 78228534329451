<template>
  <div>
    <ejs-dialog
      ref="editMultipleColumnsPopup"
      header="다중 컬럼 편집"
      :width="width"
      :isModal="true"
      :allowDragging="true"
      :showCloseIcon="true"
      :animationSettings="{ effect: 'None' }"
      :close="onPopupClosed"
    >
      <div class="window editMultipleColumns">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li
                  v-if="column.editType === 'dropdownedit'"
                  class="field"
                >
                  <div class="title">
                    {{ column.headerText }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        id="focusArea"
                        cssClass="lookupDetail-condition-dropdown"
                        v-model="value"
                        :dataSource="commonCodes"
                        :fields="fields"
                        :allowFiltering="false"
                      />
                    </li>
                  </ul>
                </li>
                <li
                  v-if="
                    (column.type === 'string' || column.type === 'number') &&
                    column.editType !== 'dropdownedit' &&
                    !column.isTimeType &&
                    !column.isDateType &&
                    !column.isDatetimeType &&
                    !column.isRemarks
                  "
                  class="field"
                >
                  <div class="title">
                    {{ column.headerText }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        ref="focusArea"
                        v-if="column.type === 'string'"
                        :type="column.isNumericType ? 'number' : 'text'"
                        v-model.trim="value"
                        :placeholder="column.headerText"
                        @click.native="value = ''"
                        @keydown.enter="submit"
                      />
                      <input-number
                        ref="focusArea"
                        v-if="column.type === 'number'"
                        v-model="value"
                        :allowDot="allowDot"
                        :allowMinus="allowMinus"
                        :displayComma="displayComma"
                        :min="min"
                        :max="max"
                        :propMaxLength="propMaxLength"
                        @keydown.enter="submit"
                      />
                    </li>
                  </ul>
                </li>
                <li
                  v-if="column.isDateType"
                  class="field"
                >
                  <div class="title">
                    {{ column.headerText }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-date
                        ref="focusArea"
                        v-model="value"
                        :format="DATE_FORMAT_YYYY_MM_DD"
                        @keydown.enter="submit"
                      />
                    </li>
                  </ul>
                </li>
                <li
                  v-if="column.isTimeType"
                  class="field"
                >
                  <div class="title">
                    {{ column.headerText }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-time
                        ref="focusArea"
                        v-model="value"
                        :format="'HH:mm'"
                        @keydown.enter="submit"
                      />
                    </li>
                  </ul>
                </li>
                <li
                  v-if="column.isDatetimeType"
                  class="field datetime"
                >
                  <div class="title">
                    {{ column.headerText }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-date
                        ref="focusArea"
                        v-model="dateValue"
                        :format="DATE_FORMAT_YYYY_MM_DD"
                        @keydown.enter="submit"
                      />
                    </li>
                    <li class="item time">
                      <input-time
                        v-model="value"
                        :format="'HH:mm'"
                        @keydown.enter="submit"
                      />
                    </li>
                  </ul>
                </li>
                <li
                  v-if="column.isRemarks"
                  class="field"
                >
                  <div class="title">
                    {{ column.headerText }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-textarea
                        ref="focusArea"
                        v-model="value"
                        :rows="3"
                      />
                    </li>
                  </ul>
                </li>
                <li
                  v-if="column.displayAsCheckBox"
                  class="field"
                >
                  <div class="title">
                    {{ column.headerText }}
                  </div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                              ref="focusArea"
                              type="checkbox"
                              v-model="value"
                            />
                            <i />
                            <div class="label">
                              {{ column.headerText }}
                            </div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                button-div="SAVE"
                :is-shortcut-button="true"
                @click.native="submit"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closePopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import InputNumber from "@/components/common/InputNumber";
import InputDate from "@/components/common/datetime/InputDate";
import InputTime from "@/components/common/datetime/InputTime";
import ErpButton from "@/components/button/ErpButton.vue";
import { DATE_FORMAT_YYYY_MM_DD } from '@/utils/date';
import {
  commonCodesGetCommonCode,
  commonCodeGetComCodeDefaultValue,
} from '@/utils/commonCodes';

export default {
  name: 'EditMultipleColumnsPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    InputTextarea,
    InputNumber,
    InputDate,
    InputTime,
    ErpButton,
  },
  data() {
    return {
      column: {
        type: null,
        editType: null,
        field: null,
        headerText: null,
        defaultValue: null,
        foreignKeyField: null,
        foreignKeyValue: null,
        groupCode: null,
        groupCodeExclude: [],
        multiEdit: {
          min: null,
          max: null,
          propMaxLength: null,
          allowDot: false,
          allowMinus: false,
          displayComma: false,
        },
        displayAsCheckBox: null,
        isNumericType: null,
        isTimeType: null,
        isDateType: null,
        isDatetimeType: null,
        isRemarks: null,
      },
      value: null,
      dateValue: null,
      selectedRowIndexes: [],
    };
  },
  computed: {
    allowDot() {
      return this.column?.multiEdit?.allowDot || false;
    },
    allowMinus() {
      return this.column?.multiEdit?.allowMinus || false;
    },
    displayComma() {
      return this.column?.multiEdit?.displayComma || true;
    },
    min() {
      return this.column?.multiEdit?.min || null;
    },
    max() {
      return this.column?.multiEdit?.max || null;
    },
    propMaxLength() {
      return this.column?.multiEdit?.propMaxLength || null;
    },
    DATE_FORMAT_YYYY_MM_DD() {
      return DATE_FORMAT_YYYY_MM_DD;
    },
    commonCodes() {
      return this.column.foreignKeyField === 'comCode' || this.column?.groupCode
        ? commonCodesGetCommonCode(this.column.groupCode, true)
          ?.filter(item => !this.column.groupCodeExclude?.includes(item.comCode))
        : this.column.dataSource;
    },
    fields() {
      return this.column.foreignKeyField === 'comCode' || this.column?.groupCode
        ? {text: 'comName', value: 'comCode'}
        : {text: this.column.foreignKeyValue, value: this.column.foreignKeyField};
    },
    width() {
      return this.column.isDatetimeType
        ? 320
        : this.column.isRemarks
          ? 400
          : 280;
    },
  },
  methods: {
    commonCodesGetCommonCode,
    onPopupClosed() {
      this.$emit('popupClosed');
    },
    async showPopup(column, selectedRowIndexes) {
      this.column = column;
      this.selectedRowIndexes = selectedRowIndexes;
      if (column?.type) {
        this.column.type = column.type.toLowerCase();
      }
      if (
        column?.editType === 'dropdownedit' &&
        Array.isArray(column?.dataSource)
      ) {
        const comCode = this.column.foreignKeyField === 'comCode'
          ? 'comCode'
          : this.column.foreignKeyField;
        this.value =
          commonCodeGetComCodeDefaultValue(column.groupCode) ||
          this.column.dataSource[0][comCode];
      } else if (
        column?.editType === "dropdownedit" &&
        column?.groupCode
      ) {
        this.value =
          commonCodeGetComCodeDefaultValue(column.groupCode) ||
          this.column.dataSource[0]["comCode"];
      } else if (column?.isDateType) {
        this.value = moment().format(DATE_FORMAT_YYYY_MM_DD);
      } else if (column?.isDatetimeType) {
        this.value = '00:00';
        this.dateValue = moment().format(DATE_FORMAT_YYYY_MM_DD);
      } else if (column?.displayAsCheckBox) {
        this.value = true;
      } else {
        if (column?.defaultValue) {
          this.value = column.defaultValue;
        }
      }
      await this.$nextTick();
      setTimeout(() => {
        if (this.column.editType === 'dropdownedit') {
          const $el = document.querySelector("#focusArea");
          if ($el) {
            setTimeout(function() {
              document.activeElement.blur();
              $el.focus();
            });
          }
        } else {
          this.$refs.focusArea?.focus();
        }
      }, 100);
    },
    closePopup() {
      this.$refs.editMultipleColumnsPopup?.hide();
    },
    async submit() {
      const value = this.column.isDatetimeType
        ? `${this.dateValue} ${this.value}`
        : this.value;
      this.$emit('popupConfirm', {
        field: this.column.field,
        value,
        rowIndexes: this.selectedRowIndexes,
      });
    },
  },
};
</script>
