<template>
  <div>
    <div class="fixed-title" >
      <div class="title-bar">
      <div class="title-btn" @click="calendarApply">
      ▼ 달력에 요금적용
      </div>
      <div class="date">
        <input
            type="button"
            class="date-btn"
            @click="onClickGoDate('prev')"
        />
        <span class="date-title">
          <ejs-datepicker
              ref="datepicker"
              :value="currentDate"
              start="Year"
              depth="Year"
              format="yyyy년 M월"
              :min="propsStratDate"
              :max="propsLastDate"
              @change="onChangeCurrentDate"
          />
        </span>
<!--        <span class="today">오늘</span>-->
        <input
            type="button"
            class="date-btn next-btn"
            @click="onClickGoDate('next')"
        />
      </div>
      <div class="section-header">
        <div class="header-right">
          <ul class="header-button">
            <li class="save keyColor">
              <erp-button
                button-div="SAVE"
                :is-shortcut-button="true"
                @click.native="save"
              >
                저장
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </div>
      <div class="day-box">
        <span
            class="day"
            :style="{ color: getDayColorValue(day) }"
            v-for="day in 7" :key="day"
        >
        {{ dayList[day - 1] }}
        </span>
      </div>
    </div>
    <table
        style="width: 100% ; height: 100%; border-collapse: collapse;"
    >
      <tbody>
      <tr
          v-for="(index, wIdx) in calendarWeekCount"
          :key="index"
      >
        <td v-for="(n, nIdx) in 7" :key="n">
          <div
              class="calendar-box"
          >
            <div class="calendar-date">
              <span>
                {{ hldyName(nIdx,wIdx) }}
              </span>
              <span
                  :style="colorValue(nIdx,wIdx)"
              >
                {{ calendarData[nIdx + (wIdx * 7)] ? calendarData[nIdx + (wIdx * 7)].date || " " : " " }}
              </span>
            </div>
            <div
                v-for="(item, idx) in dataSource.filter(record => record.bsnDate === (calendarData[nIdx + (wIdx * 7)] ? calendarData[nIdx + (wIdx * 7)].bsnDate : null))"
                :key="idx">
              <div
                  class="pay-cell"
                  @click="roomPayCorrection(item,nIdx)"
              >
                <div style="flex: 1; font-family: NanumSquare-Regular, serif">
                  {{ commonCodesGetComName("ROOM_TYPE", item.roomType) }}
                  {{ commonCodesGetComName("MEMBER_GRADE", item.memberGrade) }}
                </div>
                <div style="flex: 1">
  <!--                <input-number-->
  <!--                    style="border: none; font-family: NanumSquare-Regular, serif"-->
  <!--                    v-model="item.roomPaymt"-->
  <!--                />-->
                  <div
                      style="font-family: NanumSquare-Regular, serif; text-align: right;"
                      :style="item.hoverData && 'color:red'"
                  >
                    {{numberWithCommas(item.roomPaymt)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <room-pay-correction-popup
        v-if="isRoomPayCorrectionPopup"
        ref="roomPayCorrectionPopup"
        @popupClosed="onPopupClosed"
        @popupConfirm="onPopupConfirm"
    />
  </div>
</template>

<script>
import RoomPayCorrectionPopup from "@/views/room-reservation-management/popup/RoomPayCorrectionPopup";
import ErpButton from "@/components/button/ErpButton.vue";
import moment from "moment";
import {getDayColorValue, getDayOfWeekCaption, getFormattedDate} from '@/utils/date';
import { commonCodesGetComName,commonCodesGetColorValue } from "@/utils/commonCodes";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {numberWithCommas} from "@/utils/number";

export default {
  name: "calendar",
  mixins: [commonMixin,confirmDialogMixin],
  props: ["baseList","fromDate","toDate", "dataSource","saveCurrenDate"],
  components: {
    RoomPayCorrectionPopup,
    ErpButton,
  },
  created() {
   // this.dateSetting();
  },
  data() {
    return {
      dayList: ['일', '월', '화', '수', '목', '금', '토'],
      propsNowDate : null,
      year:null,
      month:null,
      date:null,
      calendarMonthLastDate:null,
      calendarMonthStartDay:null,
      calendarWeekCount:0,
      calendarPos:0,
      calendarDay:0,
      count:0,
      calendarData: [],
      currentDate:null,
      propsStratDate:null,
      propsLastDate:null,
      orgDataSource:[],
      isRoomPayCorrectionPopup:false,
    };
  },
  watch: {
    dataSource() {
      this.dateSetting();
    },
     currentDate() {
       this.calendar();
    },
  },
  methods: {
    numberWithCommas,
    commonCodesGetColorValue,
    getDayColorValue,
    commonCodesGetComName,
    getFormattedDate,
    async dateSetting() {
      this.orgDataSource = JSON.parse(JSON.stringify(this.dataSource));
      this.propsStratDate = moment(this.fromDate).format("YYYY-MM");
      this.propsLastDate = moment(this.toDate).format("YYYY-MM");
      this.currentDate = JSON.parse(JSON.stringify(this.saveCurrenDate || moment(this.fromDate)._d));
      this.calendar();

    },
     calendar() {
      // this.$EventBus.$emit('loaderOn');

        const formattedDate =  getFormattedDate(this.currentDate,"YYYY-MM-DD");
        this.propsNowDate =  moment(formattedDate);
        const monthStartDate  =  this.propsNowDate.startOf("month").format("YYYY-MM-DD");
        const monthLastDate =  this.propsNowDate.endOf("month").format("YYYY-MM-DD");
        let whileDate =   moment(JSON.parse(JSON.stringify(monthStartDate))).format("YYYY-MM-DD");
        const loopData = [];
        const monthStartDay =   moment(monthStartDate).day();
        for (let i = monthStartDay; i > 0; i--) {
          loopData.push({});
        }
        while (whileDate <= monthLastDate) {
          loopData.push({
            bsnDate: whileDate,
            date:whileDate.split('-')[2].split('0')[1]||whileDate.split('-')[2]
          });
          whileDate =  moment(whileDate).add(1, "days").format("YYYY-MM-DD");
        }

        this.calendarData = loopData;
        this.calendarWeekCount = Math.ceil(loopData.length / 7);


      // setTimeout(()=>{
      //   this.$EventBus.$emit('loaderOff');
      // },1);


    },
    roomPayCorrection(item,nIdx) {
      this.isRoomPayCorrectionPopup = true;
      this.$nextTick(() => {
        this.$refs.roomPayCorrectionPopup.showPopup(item,nIdx);
      });
    },
    onPopupConfirm(list) {
      const listIdx =  this.dataSource.findIndex((data)=> (data.memberGrade==list.memberGrade && data.bsnDate == list.bsnDate && data.roomType == list.roomType));
      this.dataSource[listIdx].roomPaymt = list.roomPaymt;
      this.dataSource[listIdx].hoverData = true;
      this.isRoomPayCorrectionPopup = false;
    },
    onPopupClosed() {
      this.isRoomPayCorrectionPopup = false;
    },
    colorValue(nIdx,wIdx) {
      if(nIdx==0||nIdx==6){
        return `color : ${commonCodesGetColorValue("DW_CODE",(nIdx+1).toString())}`;
      }
     const filterData = this.dataSource.filter(data => data.bsnDate === (this.calendarData[nIdx + (wIdx * 7)]&& this.calendarData[nIdx + (wIdx * 7)].bsnDate));
     if(filterData.length){
       return `color : ${this.commonCodesGetColorValue("ROOM_BSN_CODE",filterData[0]["roomBsnCode"])}`;
     }
     return ;
    },
    hldyName(nIdx,wIdx){
      const filterData = this.dataSource.filter(data => data.bsnDate === (this.calendarData[nIdx + (wIdx * 7)]&& this.calendarData[nIdx + (wIdx * 7)].bsnDate));
      if(filterData.length&&filterData[0].hldyName){
        return filterData[0]["hldyName"];
      }
      return ;
    },
     onClickGoDate(time) {
      switch (time) {
        case 'prev':
          (
              (getFormattedDate(this.currentDate,'MM')) <= (getFormattedDate(this.propsStratDate,'MM'))
              &&
              (getFormattedDate(this.currentDate,'YYYY')) <= (getFormattedDate(this.propsStratDate,'YYYY'))
          )
              ? this.errorToast("최소 달입니다")
              : this.currentDate =  moment(this.currentDate).add(-1, 'M').format();
          break;
        case 'next':
          (
              (getFormattedDate(this.currentDate,'MM')) >= (getFormattedDate(this.propsLastDate,'MM'))
              &&
              (getFormattedDate(this.currentDate,'YYYY')) >= (getFormattedDate(this.propsLastDate,'YYYY'))
          )
              ? this.errorToast("최대 달입니다")
              : this.currentDate =  moment(this.currentDate).add(1, 'M').format();
          break;
      }
    },
    save(){
      this.$emit("saveCalendar",this.currentDate);
    },
    onChangeCurrentDate(args) {
      this.currentDate =  moment(args.value).format();
    },
    refresh() {
      this.dataSource = this.orgDataSource;
    },
    calendarApply() {
      this.dataSource.forEach((data)=>{
        data.hoverData = null;
        data.day= getDayOfWeekCaption(data.bsnDate);
       const [{
           stdPaymtByFri,
           stdPaymtByMon,
           stdPaymtBySat,
           stdPaymtBySun,
           stdPaymtByThu,
           stdPaymtByTue,
           stdPaymtByWed
       }] =  this.baseList.filter((check)=>check.memberGrade==data.memberGrade&&check.roomType==data.roomType);

        switch (data.day){
          case "일":
            data.roomPaymt = stdPaymtBySun;
            break;
          case "월":
            data.roomPaymt = stdPaymtByMon;
            break;
          case "화":
            data.roomPaymt = stdPaymtByTue;
            break;
          case "수":
            data.roomPaymt = stdPaymtByWed;
            break;
          case "목":
            data.roomPaymt = stdPaymtByThu;
            break;
          case "금":
            data.roomPaymt = stdPaymtByFri;
            break;
          case "토":
            data.roomPaymt = stdPaymtBySat;
            break;
        }
      });
      this.infoToast("적용되었습니다");
    },
    changeData() {
      let n = 0;
      let key;
      const changeData = [];
      while(n<this.dataSource.length){
        let changeOb = {};
        for (key in this.dataSource[n]){
          if(this.dataSource[n][key]!=this.orgDataSource[n][key]&&key!="day"){
            changeOb[key] = this.dataSource[n][key];
          }else if(key=="bsnDate"||key=="roomType"||key=="memberGrade"){
            changeOb[key] = this.dataSource[n][key];
          }
        }
        if(Object.keys(changeOb).length>3){
          changeData.push(changeOb);
        }
        n++;
      }
      return changeData;
    }
  }
};
</script>

<style scoped>
.fixed-title{
  display: flex;
  flex-flow: column nowrap;
  position: sticky;
  top: 0;
  background: white;
  margin-bottom: 1px;
}
.title-bar {
  width: 100%;
  height: 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 10px;
  position: relative;
}
.title-bar .title-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  background: cornflowerblue;
  border-radius: 5px;
  font-family: NanumSquare-Regular, serif;
  font-weight: bold;
  color: white;
  margin-left: -20px;
  cursor: pointer;
}
.title-bar .date {
  font-family: NanumSquare-Regular, serif;
  width: 200px;
  height: 100%;
  display: flex;

}
.title-bar .date .date-title {
  font-size: 15px;
  font-family: NanumSquare-Regular, serif;
  font-weight: bold;
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.title-bar .date .date-title:hover {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.04);
}
/*.title-bar .date .today {*/
/*  width: 40px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  cursor: pointer;*/
/*}*/
/*.title-bar .date .today:hover {*/
/*  border-radius: 5px;*/
/*  background: rgba(0, 0, 0, 0.04);*/
/*}*/
.title-bar .date .date-btn {
  width: 30px;
  height: 100%;
  cursor: pointer;
  border-top-left-radius: 5px;
  background: url(../../../assets/images/common/accordion-left.png) no-repeat center;
  border: 0;
  box-sizing: border-box;
}
.title-bar .date .next-btn {
  background: url(../../../assets/images/common/accordion-right.png) no-repeat center ;
}
.title-bar .date .date-btn:hover {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.04) url(../../../assets/images/common/accordion-left-black.png) no-repeat center;
}
.title-bar .date .next-btn:hover {
  background: rgba(0, 0, 0, 0.04) url(../../../assets/images/common/accordion-right-black.png) no-repeat center;
}
.date-title .e-input-group,.date-title.e-control-wrapper, .date-title.e-date-wrapper, .date-title.e-valid-input {
  width: 100%;
  border: 0 !important;
}
.date-title .e-input-group > input {
  margin-top: 3px;
  padding: 0;
  font-size: 15px;
  font-family: NanumSquare-Regular, serif;
  font-weight: bold;
  text-align: center;
  caret-color: transparent;
  pointer-events: none;
}
.calendar-box {
  display: flex; flex-flow: column nowrap; width: 100%; height: 100%; min-width: 219px;
}
.calendar-box .calendar-date {
  text-align: right;
  font-size: 12px; font-weight: bold; padding: 5px 5px 5px 0px;
  margin-bottom: 15px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
>>>.e-input-group-icon, >>>.e-date-icon, >>>.e-icons {
  position: absolute !important;
  width: 100% !important;
}

.event-binding{
  pointer-events:none;
}
.day-box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.day-box .day {
  display: flex;
  flex: 1;
  border: 1px solid #ccc;
  border-right: 0;
  height: 25px;
  justify-content: center;
  align-items: center;
  background: rgba(211,211,211,0.3);
  font-weight: bold;
}
.day-box .day:last-child{
  border-right: 1px solid #ccc;
}
table > thead > tr > th {
  padding: 5px 0 5px 0;
  background: rgba(211,211,211,0.3);
  border: 1px solid #ccc;
}
table > tbody > tr > td {
  height: 125px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}
>>>.e-clear-icon, >>>.e-clear-icon-hide {
  display: none !important;
}
>>> .e-input-group-icon.e-date-icon::before, >>> *.e-control-wrapper .e-input-group-icon.e-date-icon::before {
  content: none !important;
}
>>> .e-input-group, >>> .e-control-wrapper, >>> .e-date-wrapper, >>> .e-valid-input {
  border: none !important;
}
>>>.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after {
  content: none !important;
}
>>> .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,>>> .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before {
  content: none !important;
}
/*>>> .e-input-group-icon::before, >>> .e-date-icon::before, >>> .e-icons::before {*/
/*  content: none !important;*/
/*}*/
.section-header {
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  right: 0;
}
.inputNumber >>> input {
  font-family: NanumSquare-Regular, serif !important;
}
.inputNumber >>> input:focus {
  background: none !important;
}
.pay-cell {
  display: flex; flex-flow: row nowrap; padding:0 5px 0 5px; box-sizing: border-box; border-radius: 3px; cursor:pointer;
}
.pay-cell:hover {
  background: #ccc;
}
</style>